<template>
    <div class="context">
        <div class="tool">
            <el-input placeholder="请输入平台名称" v-model="searchForm.searchInfo" style="width:200px;">
               <el-button slot="append" icon="el-icon-search" @click='Search'></el-button>
           </el-input>
       </div>


       <div style="margin-top:15px;width:100%">
            <el-row v-for="(page, index) of pages" :key="index" :gutter="20" style="margin:10px">
                <el-col :xs="24" :sm="12" :md="8" :lg="8" v-for="item of page" :key="item.ap_id">
                    <el-card style="height:255px;">
                        <div style="display: flex;flex-direction: column; justify-content:start;">
                            <div style="position: relative;">
                                <span style="position: absolute;top: 80%;color: white;width: 100%;text-align: center;font-size: 1.1rem;font-weight: 800;">{{item.ap_name}}</span>
                                <img :src='ImageUrl(item.ap_id)' style="width: 98%;max-height: 190px;border-radius:3%;position: block;">
                                
                            </div>
                            <div style="display: flex;flex-direction: row; justify-content:space-between;align-items:center;">
                                <span class="txt">上报车场数：{{item.count}}</span>
                                <el-link type="primary" @click="Detail(item.ap_id)" class="txt">详情</el-link>
                            </div>
                            
                        </div>
                    </el-card>
                </el-col>
            </el-row>
       </div>


       <el-pagination class="fy" layout="prev, pager, next" @current-change="current_change" :total="searchForm.total" background :page-size="searchForm.pageSize"></el-pagination>
    
    
       <el-dialog title="上报车场详情" :visible.sync="dialogVisible" width='90%' :append-to-body='true'>
            <el-button type="primary" class="el-icon-plus" style="margin-right:20px" @click="AddClick"> 新增</el-button>
            <el-table :data='ParkDataList' border stripe :highlight-current-row='true'>
                <el-table-column align="center" prop="pk_cloud_id" label="车场编号"></el-table-column>
                <el-table-column align="center" prop="pk_name" label="车场名"></el-table-column>
                <el-table-column align="center" prop="sericer_name" label="车场服务商"></el-table-column>
                <el-table-column align="center" prop="du_last_time" label="上报时间"></el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <div class="link_btn">
                            <el-link type="primary" @click="Edit(scope.row)">修改</el-link>
                            <el-link v-if="scope.row.du_state===0" type="warning" @click="Cancel(scope.row,1)">取消上报</el-link>
                            <el-link v-if="scope.row.du_state===1" type="success" @click="Cancel(scope.row,0)">重新上报</el-link>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
       </el-dialog>

    
       <el-dialog title="上报车场" :visible.sync="paramDialogVisible" width='800px' :append-to-body='true'>
           <div class="tool" style="align-items: center;margin-left: 27px;">
               <label style="width: 80px;">停车场：</label>
               <el-select v-model="paramForm.du_park_id" style="width:100%;">
                    <el-option label=" " :value="0"></el-option>
                    <el-option v-for="item in ParkList" :key="item.pk_id" :label="item.pk_name" :value="item.pk_id"></el-option>
                </el-select>
           </div>
            <component :is="componentName" :paramForm="paramForm" style="margin-top: 10px;" ref="paramComponent"></component>
            <span slot="footer" class="dialog-footer">
                <el-button @click="paramDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
       </el-dialog>
    </div>
</template>

<script>
import util from '../../util/util'

export default{
    data: function (){
        return{
            apiArr:[
                {
                    id:1,
                    name:'hbzsh',
                    imgUrl:require('../../assets/image/img/1.jpg')
                }

            ],
            searchForm:{
                searchInfo:'',
                pageNo:1,
                pageSize:12,
                total:0
            },
            DataList:[],
            ParkDataList:[],//车场详情
            ParkList:[],//所有车场


            dialogVisible:false,


            componentName:'',
            ApiId:0,
            paramDialogVisible:false,
            paramForm:{
                du_park_id:0,
                du_parameter1:'',
                du_parameter2:'',
                du_parameter3:'',
                du_parameter4:'',
                du_parameter5:'',
                du_parameter6:'',
                du_parameter7:'',
                du_parameter8:'',
                du_parameter9:'',
                du_state:0
            },

            rules:{}
        }
    },
    components: {

    },
    computed: {
        pages () {
            const pages = []
            this.DataList.forEach((item, index) => {
                const page = Math.floor(index / 3)// 3条为一行
                if (!pages[page]) {
                pages[page] = []
                }
                pages[page].push(item)
            })
            return pages
        },
        ImageUrl(){
            return function (id) {
                let url=''
                let arr= this.apiArr.filter(a=>a.id===id)
                if(arr.length>0){
                    url=arr[0].imgUrl
                }
                return url
            }
           
        }
    },
    created () {
        this.getDataList()
    },
    methods:{
        getDataList(){
            util.Get('dataupload/getdatalist', this.searchForm).then(res => {
                if (res.rpStatus === 10000) {
                    this.DataList = res.list
                    this.searchForm.total = res.total
                }
            })
        },
        current_change(page){
            this.searchForm.pageNo = page
            this.GetDataList()
        },
        Search(){
            this.getDataList()
        },
        Detail(ap_id){
            util.Get("dataupload/GetParkDetail?id="+ap_id).then(res=>{
                if(res.rpStatus===10000){
                    this.ParkDataList=res.list[0]
                    this.ParkList=res.list[1]
                }
            })
            this.dialogVisible=true
            this.title='上报车场详情'
            this.ApiId=ap_id
            this.loadComponent()
        },
        loadComponent(){
            var name=''
            let arr= this.apiArr.filter(a=>a.id===this.ApiId)
            if(arr.length>0){
                name=arr[0].name
            }
            console.log(name)
            import('../../components/api/'+name+'.vue').then(component=>{
                this.componentName=component.default
            })
        },
        AddClick(){
            this.paramForm.du_park_id=0
            this.paramDialogVisible=true
            this.paramForm.du_parameter1=''
            this.paramForm.du_parameter2=''
            this.paramForm.du_parameter3=''
            this.paramForm.du_parameter4=''
            this.paramForm.du_parameter5=''
            this.paramForm.du_parameter6=''
            this.paramForm.du_parameter7=''
            this.paramForm.du_parameter8=''
            this.paramForm.du_parameter9=''
        },
        Edit(row){
            this.paramForm.du_park_id=row.du_park_id
            this.paramDialogVisible=true
            this.paramForm.du_parameter1=row.du_parameter1
            this.paramForm.du_parameter2=row.du_parameter2
            this.paramForm.du_parameter3=row.du_parameter3
            this.paramForm.du_parameter4=row.du_parameter4
            this.paramForm.du_parameter5=row.du_parameter5
            this.paramForm.du_parameter6=row.du_parameter6
            this.paramForm.du_parameter7=row.du_parameter7
            this.paramForm.du_parameter8=row.du_parameter8
            this.paramForm.du_parameter9=row.du_parameter9
            this.dialogVisible=true
            this.title='上报车场详情'
            this.ApiId=row.du_api_id
            this.loadComponent()
        },
        Cancel(row,state){
            let msg='确定'+(state===0?'设置':'取消')+'车场“' + row.pk_name + '”上报？'
            this.$confirm(msg, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.paramForm.du_state=state
                    this.paramForm.du_park_id=row.du_park_id
                    this.paramForm.du_api_id=row.du_api_id

                    util.Post('dataupload/parkupload',this.paramForm).then(res=>{
                    if(res.rpStatus===10000){
                        this.$message.success((state===0?'设置':'取消')+'车场上报成功')
                        this.paramDialogVisible=false
                        this.Detail(this.ApiId)
                    }
                    else{
                        this.$message.error((state===0?'设置':'取消')+"车场上报失败")
                    }
                })
            }).catch(e => e)
        },
        submit(){
            if(this.paramForm.du_park_id===0){
                this.$message.error('请选择上报车场')
                return
            }
            const flag = this.$refs.paramComponent.validateForm()
            if (flag) {
                this.paramForm.du_api_id=this.ApiId
                util.Post('dataupload/parkupload',this.paramForm).then(res=>{
                    if(res.rpStatus===10000){
                        this.$message.success('设置车场上报成功')
                        this.paramDialogVisible=false
                        this.Detail(this.ApiId)
                    }
                    else{
                        this.$message.error("设置车场上报失败")
                    }
                })
            }
        }
    }
}
</script>


<style scoped>
.context{
    display: flex;
    flex-direction: column;
    justify-content: start;
    height: 100%;
}
.tool{
    display: flex;
    flex-direction: row;
    justify-content: start;
}
.el-table{
    margin-top:10px;
}
.el-link{
    margin-right: 10px;
    font-size: 10px;
}
.txt{
    margin-top: 5px;
    font-weight: 600;
    font-family:Arial, Helvetica, sans-serif;
    font-size: 0.9rem;
}




::v-deep .el-dialog{
  --el-bg-color:#000741 !important;
}
::v-deep .el-dialog__header{
  --el-text-color-primary:#1effff;
  --el-text-color-regular:#fff;
  padding: 0px !important;
  background-color: #309CE2;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-dialog__title{
    color: white;
}

::v-deep .el-dialog__headerbtn .el-dialog__close{
    color: white;
} 
</style>